export default [
  {
    id: 67,
    name: 'coolray',
    brand: 'geely',
    model: 'Geely Coolray',
    price: '',
    description:
      'Каждое движение привлекает внимание: потрясающие линии и каждая деталь нового BMW X1 оставляют яркое впечатление.',
    conditions: [
      'Панорамная крыша с люком',
      'Бесключевой доступ',
      'Ассистент парковки',
      'Запуск двигателя с кнопки',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 65,
    name: 'atlas',
    brand: 'geely',
    model: 'Geely Atlas Pro',
    price: '',
    description:
      'Новый BMW X2 с первого взгляда показывает свою спортивную сущность. Мощный и атлетичный, он предлагает динамику и манёвренность, которые в этом классе автомобилей не имеют себе равных.',
    conditions: [
      'Полный привод',
      'Панорамная крыша с люком',
      'Бесключевой доступ',
      'Система кругового обзора 360°',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 66,
    name: 'tugella',
    brand: 'geely',
    model: 'Geely Tugella',
    price: '',
    description:
      'Новый BMW X2 с первого взгляда показывает свою спортивную сущность. Мощный и атлетичный, он предлагает динамику и манёвренность, которые в этом классе автомобилей не имеют себе равных.',
    conditions: [
      'Панорамная крыша с люком',
      'Вентиляция передних седений',
      'Система кругового обзора 360°',
      'Cистема распознавания дорожных знаков',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 74,
    name: 'monjaro',
    brand: 'geely',
    model: 'Geely Monjaro',
    price: '',
    description:
      'Новый BMW X2 с первого взгляда показывает свою спортивную сущность. Мощный и атлетичный, он предлагает динамику и манёвренность, которые в этом классе автомобилей не имеют себе равных.',
    conditions: [
      'Система кругового обзора 360°',
      'Два сенсорных дисплея диагональю 12,3’’',
      'Панорамная крыша с люком',
      'Cистема распознавания дорожных знаков',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 70,
    name: 'c5',
    brand: 'omoda',
    model: 'Omoda C5',
    price: '',
    description:
      'Каждое движение привлекает внимание: потрясающие линии и каждая деталь нового BMW X1 оставляют яркое впечатление.',
    conditions: [
      'Панорамный люк',
      'Система кругового обзора',
      'Бесключевой доступ',
      'Мониторинг слепых зон',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  // {
  //   id: 15,
  //   name: 'glb',
  //   brand: 'mb',
  //   model: 'Mercedes-Benz GLB',
  //   price: '',
  //   description:
  //     'Откройте для себя просторный Mercedes-Benz GLB с выразительным дизайном внедорожника системой MBUX и семью посадочными местами.',
  //   conditions: [
  //     'Подписка от 6 месяцев',
  //     'до 30 000км пробега',
  //     'Возможен выкуп по окончанию подписки',
  //   ],
  //   type: 3,
  //   service: [1, 2, 3, 4],
  // },
  {
    id: 16,
    name: 'eclass_sedan',
    brand: 'mb',
    model: 'Mercedes-Benz E-Класс',
    price: '',
    description:
      'Откройте для себя превосходные качества нового седана E-Класса. Динамичного, элегантного и роскошного как никогда.',
    conditions: [
      'Подписка от 6 месяцев',
      'до 30 000км пробега',
      'Возможен выкуп по окончанию подписки',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 27,
    name: 'eclass_cupe',
    model: 'Mersedes-Benz E-Класс Купе',
    price: '',
    description:
      'В нём гармонично сочетаются инновационные технологические решения, великолепная динамика, превосходные ходовые качества и исключительный уровень комфорта',
    conditions: [
      'Подписка от 6 месяцев',
      'до 30 000км пробега',
      'Возможен выкуп по окончанию подписки',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  // {
  //   id: 19,
  //   name: 'cla',
  //   brand: 'mb',
  //   model: 'Mercedes-Benz CLA 4MATIC',
  //   price: '',
  //   description:
  //     'Mercedes-Benz CLA Купе – результат возвращения компании к компакт-классу, яркий, но неожиданный.',
  //   conditions: [
  //     'Подписка от 6 месяцев',
  //     'до 30 000км пробега',
  //     'Возможен выкуп по окончанию подписки',
  //   ],
  //   type: 1,
  //   service: [1, 2, 3, 4],
  // },
  // {
  //   id: 2,
  //   name: 'x1',
  //   brand: 'bmw',
  //   model: 'BMW X1',
  //   price: '',
  //   description:
  //     'Каждое движение привлекает внимание: потрясающие линии и каждая деталь нового BMW X1 оставляют яркое впечатление.',
  //   conditions: [
  //     'Подписка от 6 месяцев',
  //     'до 30 000км пробега',
  //     'Возможен выкуп по окончанию подписки',
  //   ],
  //   type: 2,
  //   service: [1, 2, 3, 4],
  // },
  {
    id: 1,
    name: '2',
    brand: 'bmw',
    model: 'BMW 2 серии',
    price: '',
    description:
      'BMW 2 серии Gran Coupe создан, чтобы задавать новые стандарты. ',
    conditions: [
      'Подписка от 6 месяцев',
      'до 30 000км пробега',
      'Возможен выкуп по окончанию подписки',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  // {
  //   id: 6,
  //   name: 'x2',
  //   brand: 'bmw',
  //   model: 'BMW X2',
  //   price: '',
  //   description:
  //     'Новый BMW X2 с первого взгляда показывает свою спортивную сущность. Мощный и атлетичный, он предлагает динамику и манёвренность, которые в этом классе автомобилей не имеют себе равных.',
  //   conditions: [
  //     'Подписка от 6 месяцев',
  //     'до 30 000км пробега',
  //     'Возможен выкуп по окончанию подписки',
  //   ],
  //   type: 1,
  //   service: [1, 2, 3, 4],
  // },
  // {
  //   id: 12,
  //   name: '3',
  //   brand: 'bmw',
  //   model: 'BMW 3 серии',
  //   price: '',
  //   description:
  //     'Едва выехав на дорогу, новый BMW 3 серии оставляет позади предрассудки и опережает самые смелые ожидания.',
  //   conditions: [
  //     'Подписка от 6 месяцев',
  //     'до 30 000км пробега',
  //     'Возможен выкуп по окончанию подписки',
  //   ],
  //   type: 2,
  //   service: [1, 2, 3, 4],
  // },
  {
    id: 38,
    name: 'x4',
    brand: 'bmw',
    model: 'BMW X4 new',
    price: '',
    description:
      'BMW X4 привлекает к себе внимание, всегда и везде. Его экстравертный дизайн соединяет в себе изысканный стиль и мощь.',
    conditions: [
      'Подписка от 6 месяцев',
      'до 30 000км пробега',
      'Возможен выкуп по окончанию подписки',
    ],
    type: 2,
    service: [1, 2, 3, 4],
  },
  {
    id: 18,
    name: '7',
    brand: 'bmw',
    model: 'BMW 7 серии',
    price: '',
    description:
      'BMW 7 серии обращает на себя внимание внушительным обликом, исключительной мощностью и максимальным комфортом.',
    conditions: [
      'Подписка от 6 месяцев',
      'до 30 000км пробега',
      'Возможен выкуп по окончанию подписки',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 50,
    name: 'jolion',
    brand: 'haval',
    model: 'HAVAL JOLION',
    price: '',
    description:
      'BMW 2 серии Gran Coupe создан, чтобы задавать новые стандарты. ',
    conditions: [
      'Бесключевой доступ',
      'Проекционный. дисплей',
      'Система кругового обзора 360°',
      'Поддержка Android Auto/Apple Carplay',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 48,
    name: 'f7',
    brand: 'haval',
    model: 'HAVAL F7',
    price: '',
    description:
      'Новый BMW X2 с первого взгляда показывает свою спортивную сущность. Мощный и атлетичный, он предлагает динамику и манёвренность, которые в этом классе автомобилей не имеют себе равных.',
    conditions: [
      'Крыша с люком',
      'Вентилиция сиденья водителя',
      'Система кругового обзора 360° с автоматической парковкой',
      'Поддержка Android Auto/Apple Carplay',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 49,
    name: 'f7x',
    brand: 'haval',
    model: 'HAVAL F7x',
    price: '',
    description:
      'Каждое движение привлекает внимание: потрясающие линии и каждая деталь нового BMW X1 оставляют яркое впечатление.',
    conditions: [
      'Крыша с люком',
      'Вентилиция сиденья водителя',
      'Система кругового обзора 360° с автоматической парковкой',
      'Поддержка Android Auto/Apple Carplay',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 51,
    name: 'tigo7',
    brand: 'chery',
    model: 'Chery TIGGO 7 PRO',
    price: '',
    description:
      'Каждое движение привлекает внимание: потрясающие линии и каждая деталь нового BMW X1 оставляют яркое впечатление.',
    conditions: [
      'Панорамная крыша с люком',
      'Бесключевой доступ',
      'Система кругового обзора 360°',
      'Система дистанционного запуска двигателя и прогрева салона',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 52,
    name: 'tigo8',
    brand: 'chery',
    model: 'Chery TIGGO 8 PRO',
    price: '',
    description:
      'Новый BMW X2 с первого взгляда показывает свою спортивную сущность. Мощный и атлетичный, он предлагает динамику и манёвренность, которые в этом классе автомобилей не имеют себе равных.',
    conditions: [
      'Панорамная крыша с люком',
      'Бесключевой доступ',
      'Система кругового обзора 360°',
      'Система дистанционного запуска двигателя и прогрева салона',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 63,
    name: 'lx',
    brand: 'exeed',
    model: 'Exeed LX',
    price: '',
    description:
      'Каждое движение привлекает внимание: потрясающие линии и каждая деталь нового BMW X1 оставляют яркое впечатление.',
    conditions: [
      'Панорамная крыша с люком',
      'Бесключевой доступ',
      'Система кругового обзора 360°',
      'Встроенный регистратор',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 64,
    name: 'txl',
    brand: 'exeed',
    model: 'Exeed TXL',
    price: '',
    description:
      'Новый BMW X2 с первого взгляда показывает свою спортивную сущность. Мощный и атлетичный, он предлагает динамику и манёвренность, которые в этом классе автомобилей не имеют себе равных.',
    conditions: [
      'Панорамная крыша с люком',
      'Передние коджанные сиденья с вентилицией',
      'Система кругового обзора 360°',
      'Cистема распознавания дорожных знаков',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 61,
    name: 'vx',
    brand: 'exeed',
    model: 'Exeed VX',
    price: '',
    description:
      'BMW 2 серии Gran Coupe создан, чтобы задавать новые стандарты. ',
    conditions: [
      'Панорамная крыша с люком',
      '20-дюймовые алюминиевые литые диски',
      'Система кругового обзора 360°',
      'Ионизация воздуха в салоне',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 43,
    name: 'etron',
    brand: 'audi',
    model: 'Audi E-Tron',
    price: '',
    description:
      'Каждое движение привлекает внимание: потрясающие линии и каждая деталь нового BMW X1 оставляют яркое впечатление.',
    conditions: [
      'Панорамная крыша с люком',
      'Бесключевой доступ',
      'Система кругового обзора 360°',
      'Система дистанционного запуска двигателя и прогрева салона',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 72,
    name: 'pro',
    brand: 'evolute',
    model: 'Evolute i-PRO',
    price: '',
    description: '',
    conditions: [
      'Запас хода – 433 км',
      'Электродвигатель 110 кВт',
      '7" сенсорный дисплей',
      'Климат-контроль',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 73,
    name: 'e5',
    brand: 'kaiyi',
    model: 'KAIYI E5',
    price: '',
    description: '',
    conditions: [
      'Бесключевой доступ',
      'Люк с электроприводом',
      'Камеры кругового обзора',
      'Беспроводное зарядное устройство',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 75,
    name: 'uni',
    brand: 'changan',
    model: 'Changan UNI-K',
    price: '',
    description: '',
    conditions: [
      'Отделка сидений кожей Nappa с вентиляцией',
      'Подогрев сидений второго ряда',
      'Акустическая система Hi-End с 12 динамиками Sony',
      'Камера кругового обзора 360°',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 76,
    name: 'dashing',
    brand: 'jetour',
    model: 'Jetour Dashing',
    price: '',
    description: '',
    conditions: [
      'Дисплей для отображения показателей окружающей среды',
      'Напоминание о забытом ключе и забытом телефоне',
      'Бесключевой доступ и запуск двигателя кнопкой',
      'Панорамная система помощи при парковке 360°',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 77,
    name: 'x90plus',
    brand: 'jetour',
    model: 'Jetour X90 Plus',
    price: '',
    description: '',
    conditions: [
      'Система распознавания владельца со световым подтверждением',
      'Система кругового обзора 360° (HD изображение)',
      'Вентиляция подушки сиденья водителя',
      'Панорамная крыша с электроприводом',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
    {
    id: 82,
    name: 'M7',
    brand: 'AITO',
    model: 'AITO M7',
    price: '',
    description: '',
    conditions: [
      'Камера помощи при парковке',
      'Панорамная камера 360°',
      'Звукоизоляционное остекление',
      'Приветственное освещение',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 81,
    name: 'M5',
    brand: 'AITO',
    model: 'AITO M5',
    price: '',
    description: '',
    conditions: [
      'Камера помощи при парковке ',
      'Панорамная камера 360°',
      'Автоматическое экстренное торможение AEB',
      'Предупреждение о пешеходах',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
  {
    id: 87,
    name: 'GS3 GB',
    brand: 'GAC',
    model: 'GAC GS3 GB',
    price: '',
    description: '',
    conditions: [
      'Панорамная крыша',
      'Лобовое стекло с подогревом',
      'Климат-контроль',
      'Кожаный салон',
    ],
    type: 1,
    service: [1, 2, 3, 4],
  },
];