<template>
  <main class="home" role="main">
    <section class="home__section home__section--catalog" id="catalog">
      <div class="catalog">
        <offer-promo v-if="$route.query.promo"></offer-promo>
        <div v-if="!loading" class="catalog__filter" :class="{ 'is-active': showFilters }">
          <catalog-filter
            @closeFiltersDialog="closeFilters"
            @showFiltersCount="showFiltersCount"
            @filterCarsRequest="filterCarsRequest"
          >
          </catalog-filter>
        </div>
        <div class="catalog__cards">
          <div class="catalog__selection">
            <div class="catalog__search" @click="openFilters">
              Параметры поиска&nbsp;<span>({{ filtersCount }})</span>
            </div>
            <catalog-select @sortCards="sortCards"></catalog-select>
          </div>
          <transition-group name="fade">
            <template v-if="!isFetchCars">
              <card
                v-for="card in cards.slice(0, limit)"
                :card="card"
                :rclass="getClass(card.rent_class_id)"
                :key="card.id"
              ></card>
            </template>
            <template v-else>
              <card-placeholder v-for="n in 8" :key="n"></card-placeholder>
            </template>
          </transition-group>
          <card-call></card-call>
        </div>
      </div>
    </section>
    <offer-call v-if="!$route.query.promo"></offer-call>
  </main>
</template>

<script setup>
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import {useStore} from 'vuex';
import Card from '@/components/Card';
import CardCall from '@/components/CardCall';
import CatalogFilter from '@/components/CatalogFilter';
import OfferCall from '@/components/OfferCall';
import OfferPromo from '@/components/OfferPromo';
import CatalogSelect from '@/components/CatalogSelect';
import CardPlaceholder from '@/components/CardPlaceholder';
import { bottomOfElement } from '@/utils';
import {computed, onMounted, ref, watch} from 'vue';
import {onBeforeRouteLeave, useRoute} from 'vue-router';
import { useMeta } from 'vue-meta';
import routesInfo from '@/router/const';

useMeta({
  title: routesInfo.catalog.title,
  description: routesInfo.catalog.description,
  keywords: routesInfo.catalog.keywords
});

const isFetchCars = ref(true);
const showFilters = ref(false);
const rclasses = ref([]);
const sorting = ref(null);
const filtersCount = ref(0);
const limit = ref(8);
const loading = ref(true);
const sortBy = ref('lease_month_price');

const $route = useRoute();
const $store = useStore();

const cars = computed(() => $store.getters['cars/cars']);
const fetchCars = computed(() => $store.getters['cars/fetchCars']);
const rentClasses = computed(() => $store.getters['cars/rentClasses']);
const rclass = computed(() => $store.getters['cars/rclass']);
const classes = computed(() => $store.getters['cars/classes']);

const idsCardsForTop = [438, 448, 449];

const cards = ref([]);

watch(() => [sorting.value, isFetchCars.value], () => {
  if (isFetchCars.value) return;

  cards.value = updateCards();
},{deep: true});


const updateCards = () => {
  let cards = [];
  const topCards = [];

  if (cars.value) {
    if (filtersCount.value === 0 && idsCardsForTop.length > 0) {
      cards = [...cars.value.filter((card) => {
        if (idsCardsForTop.includes(card.id)) {
          topCards.push(card);
        } else {
          return card;
        }
      })];
    } else {
      cards = [...cars.value];
    }
  }

  if ((sorting.value === null || sorting.value === 'id') && filtersCount.value === 0) {
    return [...topCards, ...cards];
  }

  return [...topCards, ...cards].sort((a, b) => {
    if (sorting.value === 'price-asc') {
      return a[sortBy.value] - b[sortBy.value];
    } else if (sorting.value === 'price-desc') {
      return b[sortBy.value] - a[sortBy.value];
    }
  });
};

const filterCarsRequest = async (data) => {
  isFetchCars.value = true;
  await $store.dispatch('cars/fetchCars', data);
  await $store.dispatch('cars/setCars', fetchCars.value);
  isFetchCars.value = false;
};

 const openFilters = () => {
  disablePageScroll(document.querySelector('.catalog__filter'));
  showFilters.value = true;
};

const closeFilters = () => {
  enablePageScroll(document.querySelector('.catalog__filter'));
  showFilters.value = false;
};

const sortCards = (type) => {
  sorting.value = type;
};

const showFiltersCount = (val) => {
  filtersCount.value = val;
};

const loadCards = () => {
  const catalog = document.getElementById('catalog');

  if (catalog === null) return;

  const bottomOfCatalog = bottomOfElement(catalog);

  if (bottomOfCatalog) {
    if (limit.value <= cards.value.length) {
      limit.value = limit.value + 9;
    }
  }
};

const getClass = (id) => {
  if (classes.value === undefined) return;

  return classes.value.find((obj) => {
    return obj.id === id;
  });
};
const setRclass = () => {
  if (rclass.value) {
    $store.dispatch('auth/saveRclass', '');
  }
};
const getQuery = () => {
  const query = $route.query;
  const querySegment = query.segment;
  const queryClass = Number(query.rclass);

  const result = {};

  switch (querySegment) {
    case 'premium':
      result.segments = [2];
      break;
    case 'comfort':
      result.segments = [1];
      break;
    case 'standart':
      result.segments = [0];
      break;
  }

  if (queryClass) {
    result.rent_classes = [queryClass];
  }

  return result;
};

onMounted(()=> {
  const query = getQuery();

  Promise.all([
    $store.dispatch('cars/fetchClasses'),
    $store.dispatch('cars/fetchCars', query),
  ])
    .then(() => {
      rclasses.value = rentClasses.value;

      if (!rclass.value) {
        return $store.dispatch('auth/fetchRclass');
      } else {
        return Promise.resolve();
      }
    })
    .then(() => {
      $store.dispatch('cars/setCars', fetchCars.value);

      setRclass();
      loading.value = false;
      isFetchCars.value = false;
      window.addEventListener('scroll', () => loadCards(), false);
    })
    .catch(error => {
      console.error('Error:', error);
      loading.value = false;
    });
});

onBeforeRouteLeave(() => {
  window.removeEventListener('scroll',() => loadCards(), false);
});

</script>
