<template>
  <transition name="fade">
    <div v-if="isShown" class="popup popup--dailog">
      <div class="popup__overlay">
      </div>
      <div class="popup__loader">
        <div class="popup__icon" :class="{'popup__icon--rotateIn': isShown}">
          <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="49" stroke="#2E58F5" stroke-width="2"/><circle cx="50" cy="50" r="29" stroke="#2E58F5" stroke-width="2"/><path d="M50 15.286c19.172 0 34.714 15.542 34.714 34.714h2c0-20.277-16.437-36.714-36.714-36.714v2Z" fill="#2E58F5"/><path d="m30.877 28.071 2.28 3.95a.2.2 0 0 0 .121.093l3.743 1.003a.2.2 0 0 1 .121.093l8.43 14.6a.2.2 0 0 0 .274.074l4.602-2.657a.2.2 0 0 0 .073-.273l-8.43-14.602a.2.2 0 0 1-.02-.151l1.003-3.743a.2.2 0 0 0-.02-.152l-2.28-3.949M69.204 71.597l-3.21-5.558a.2.2 0 0 0-.12-.093l-3.743-1.003a.2.2 0 0 1-.122-.093l-8.43-14.602a.2.2 0 0 0-.273-.073l-4.602 2.657a.2.2 0 0 0-.074.274l8.43 14.6a.2.2 0 0 1 .02.153L56.077 71.6a.2.2 0 0 0 .02.152l3.209 5.558M72.631 30.77l-4.094 2.363a.2.2 0 0 0-.093.122l-1.003 3.743a.2.2 0 0 1-.093.121l-14.601 8.43a.2.2 0 0 0-.073.273l2.657 4.603a.2.2 0 0 0 .273.073l14.601-8.43a.2.2 0 0 1 .152-.02l3.743 1.003a.2.2 0 0 0 .152-.02l3.599-2.078M21.784 59.273l4.26-2.46a.2.2 0 0 1 .151-.02l3.743 1.003a.2.2 0 0 0 .152-.02l14.6-8.43a.2.2 0 0 1 .274.074l2.657 4.602a.2.2 0 0 1-.073.273l-14.601 8.43a.2.2 0 0 0-.093.121L31.85 66.59a.2.2 0 0 1-.093.122l-3.764 2.173" stroke="#2E58F5" stroke-width="2"/><circle cx="50.29" cy="50.267" r="9" transform="rotate(-30 50.29 50.267)" fill="#fff" stroke="#2E58F5" stroke-width="2"/></svg>
        </div>
        <div class="popup__content">
          <div class="popup__title">Колеса крутятся...</div>
          <div class="popup__text">Еще чуть-чуть и все получится!</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'Loader',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      isShown: this.show,
    };
  },
  watch: {
    show(value) {
      this.isShown = value;
    }
  }
};

</script>