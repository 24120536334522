<template>
  <main class="home" role="main">
    <section class="home__section home__section--profile" id="profile">
      <div class="profile">
        <div class="profile__head">
          <div class="profile__title">Добро пожаловать в&nbsp;Личный Кабинет</div>
        </div>
        <div v-if="loaded" class="profile__content">
          <div class="profile__intro">
            <div class="profile__image is-active">
              <picture>
                <img v-if="loaded" :src="car.photos[0]" alt="image" title="" />
              </picture>
              <a href="#" class="profile__in-map" @click.prevent="showMap = true">На карте</a>
            </div>
            <div class="profile__information">
              <div class="profile__subtitle">{{ car.make }} {{ car.version }}</div>
              <div v-if="false" class="profile__downloads">
                <a :href="docs.act" class="profile__download">Договор</a>
                <a :href="docs.proxy" class="profile__download">Доверенность</a>
                <a :href="docs.insurance" class="profile__download">Страховка</a>
              </div>
            </div>
            <div class="profile__map" :class="{ 'is-active': showMap }">
              <yandex-map v-if="loading.map" :coords="[car.latitude, car.longitude]" :settings="mapSettings" :controls="[]">
                <ymap-marker :marker-id="'car-point'" :coords="[car.latitude, car.longitude]" :balloon="balloon"></ymap-marker>
              </yandex-map>
              <div class="profile__in-image" @click="showMap = false">Вернуться</div>
            </div>
          </div>
          <div class="profile__cards">
            <div class="profile__card">
              <div class="profile__subtitle">Всего осталось КМ</div>
              <div class="profile__indication">{{ mileage }}<span>/год</span></div>
              <div class="profile__scale">
                <span :style="{ width: mileagePercent + '%' }"></span>
              </div>
            </div>
            <div class="profile__card">
              <div class="profile__subtitle">До конца подписки</div>
              <div class="profile__indication">{{ mileageDays }} дней</div>
              <div class="profile__scale">
                <span :style="{ width: mileageDaysPercent + '%' }"></span>
              </div>
            </div>
          </div>
          <div class="profile__title">История платежей</div>
          <div class="profile__table" v-if="payments">
            <Table :items="payments"></Table>
          </div>

          <div class="profile__title">Персональная информация</div>
          <form class="profile__driver profile__driver--1">
            <div class="profile__inputs profile__inputs--1">
              <fieldset class="profile__fieldset" disabled>
                <legend class="profile__legend">ФИО</legend>
                <input class="profile__input" type="text" name="name" placeholder="Иван Иванович Иванов"
                  :value="user.name" />
              </fieldset>
              <fieldset class="profile__fieldset" disabled>
                <legend class="profile__legend">E-mail</legend>
                <input class="profile__input" type="email" name="email" placeholder="name@email.ru" :value="user.email" />
                <div class="profile__error">Неверный формат электронной почты</div>
              </fieldset>
              <fieldset class="profile__fieldset" disabled>
                <legend class="profile__legend">Телефон</legend>
                <input class="profile__input" type="tel" name="tel" placeholder="+7 ***" :value="user.phone" />
                <div class="profile__error">Неверный формат телефона</div>
              </fieldset>
            </div>
          </form>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
import * as links from '../store/links';
import Table from '../components/write-off-table/Table';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import { useMeta } from 'vue-meta';
import routesInfo from '@/router/const';
import { computed, ref, watch } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const API_KEY = '0f08cdd4-c2ee-45f6-9ef0-b0d49332bb19';
const mapSettings = ref({
  apiKey: API_KEY,
  zoom: 7
});
const balloon = ref({
  body: 'Ваш автомобиль'
});
const loading = ref({
  map: false
});
const car = ref({});
const meta = ref({});
const docs = ref({});
const rclass = ref({});
const loaded = ref(false);
const carDefault = {
  photos: [
    require('@/assets/images/catalog/car-placeholder.png'),
  ],
};
const maxMileage = ref(30000);
const fakeMileageFactor = 28900;
const bookingDate = ref(new Date(2021, 0, 1));
const daysTotal = ref(0);
const daysToEnd = ref(0);
const showMap = ref(false);
const payments = ref([]);
const carMileage = ref(0);

useMeta({
  title: routesInfo.profile.title,
  description: routesInfo.profile.description,
  keywords: routesInfo.profile.keywords
});

const $router = useRouter();
const $store = useStore();

const user = computed(() => $store.getters['auth/user']);

const mileage = computed(() => {
  return maxMileage.value - carMileage.value;
});

const mileagePercent = computed(() => {
  return (mileage.value / maxMileage.value) * 100;
});

const mileagePerMonth = computed(() => {
  return maxMileage.value / 12;
});

const mileageMonth = computed(() => {
  //TODO добавить поддержку високосных годов
  const pastMonths = Math.ceil((new Date().getTime() - bookingDate.value.getTime()) / 2629800000);
  const currentMonth = pastMonths + 1;
  const monthsLeft = 12 - (currentMonth - 1);
  const mileagePerMonthLeft = mileage.value - ((monthsLeft - 1) * mileagePerMonth.value);
  return mileagePerMonthLeft > mileagePerMonth.value ? mileagePerMonth.value : mileagePerMonthLeft;
});

const mileageMonthPercent = computed(() => {
  return (mileageMonth.value / mileagePerMonth.value) * 100;
});

const mileageDays = computed(() => {
  return daysToEnd.value;
});

const mileageDaysPercent = computed(() => {
  return (daysToEnd.value / daysTotal.value) * 100;
});

async function deleteProfile() {
  const deleteConfirmation = confirm('Удалить профиль?');

  if (deleteConfirmation) {
    axios.delete(
      `${links.API}/user`,
      {
        loading: { message: 'Trying to login...' }
      }
    )
      .then(async () => {
        await $router.push('/registration');
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

function profileRequest() {
  bookingDate.value = new Date(user.value.booked_at);
  docs.value.act = user.value.documents.act;
  docs.value.proxy = user.value.documents.proxy;
  docs.value.insurance = user.value.documents.insurance;
  daysToEnd.value = user.value.days.remain;
  daysTotal.value = user.value.days.total;

  if (user.value?.rate_plan_max_trip) {
    maxMileage.value = user.value.rate_plan_max_trip;
  }

  Promise.all([carRequest(), paymentsRequest()]);
}

async function carRequest() {
  axios.get(
    `${links.API}/cars/${user.value.car_id}`,
    {
      loading: { message: 'Trying to login...' }
    }
  )
    .then(async (response) => {
      car.value = response.data;

      carMileage.value = car.value.total_trip;

      if (car.value.latitude && car.value.longitude) {
        loading.value.map = true;
        mapSettings.value = {
          apiKey: API_KEY,
          coords: [car.value.latitude, car.value.longitude],
          zoom: 7
        };
      }

      setDefault();
    })
    .catch((error) => {
      console.error(error);
      $store.dispatch('auth/checkAuth', error);
    });
}

async function paymentsRequest() {
  axios.get(
    `${links.API}/user/payments`,
    {
      loading: { message: 'Trying to login...' }
    }
  )
    .then(async (response) => {
      payments.value = response.data;
      payments.value.sort((a, b) => {
        const nameA = a?.payment_date.toLowerCase();
        const nameB = b?.payment_date.toLowerCase();

        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;

        return 0;
      });
    })
    .catch((error) => {
      console.error(error);
      $store.dispatch('auth/checkAuth', error);
    });
}

async function rentClassRequest() {
  axios.get(
    `${links.API}/rent-classes`,
    {
      loading: { message: 'Trying to login...' }
    }
  )
    .then(async (response) => {
      rclass.value = response.data.find(rClassEl => rClassEl.id === car.value.rent_class_id);

      if (rclass.value && rclass.value.photos.length) {
        car.value.photos = rclass.value.photos;
      } else {
        car.value.photos = carDefault.photos;
      }

      loaded.value = true;
    })
    .catch((error) => {
      console.error(error);
      $store.dispatch('auth/checkAuth', error);
    });
}

function setDefault() {
  if (!car.value?.photos.length) {
    rentClassRequest();
  } else {
    loaded.value = true;
  }
}

watch(() => user.value, () => {
  if (!user.value) return;

  profileRequest();
}, {deep: true, immediate: true});
</script>

<style scoped>
.ymap-container {
  height: 100%;
}
</style>