<template>
  <div class="profile__step profile__step--2" :class="{'is-active': active, 'is-complete': completed}">
    <div class="profile__headline">
      <div class="profile__circle">
        <progress-circle :active="active" :progress="progress" :max-progress="5" :error="error" :completed="completed"></progress-circle>
      </div>
      <div>
        <div class="profile__title">Шаг 2</div>
        <div class="profile__text">Документы</div>
      </div>
    </div>
    <form class="profile__form" :class="{'profile__form--error': error}" @submit.prevent="checkDocuments">
      <div class="profile__text">Загрузите водительское удостоверение и фотографию с паспортом в соответствующие поля</div>
      <div class="profile__documents" :class="{ 'profile__documents--disabled': loading }">
        <upload-image :text="'Фото первой <br>страницы паспорта'" :params="{'type': 'passport', 'side': 'front'}" @changed="handleImages" @onClear="setDefaultStatus" :status="documents.passport.front"></upload-image>
        <upload-image :text="'Фото паспорта с <br>регистрацией'" :params="{'type': 'passport', 'side': 'back'}" @changed="handleImages" @onClear="setDefaultStatus" :status="documents.passport.back"></upload-image>
        <upload-image :text="'Лицевая сторона <br>ВУ'" :params="{'type': 'driver_license', 'side': 'front'}" @changed="handleImages" @onClear="setDefaultStatus" :status="documents.driver_license.front"></upload-image>
        <upload-image :text="'Обратная сторона <br>ВУ'" :params="{'type': 'driver_license', 'side': 'back'}" @changed="handleImages" @onClear="setDefaultStatus" :status="documents.driver_license.back"></upload-image>
        <upload-image :text="'Селфи'" :params="{'type': 'selfie', 'side': 'front'}" @changed="handleImages" @onClear="setDefaultStatus" :status="documents.selfie.front"></upload-image>
        <div v-if="loading" class="profile__status profile__status--mobile">
          <div>
            <svg class="profile__status-icon" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8.5" cy="8.5" r="8.5" fill="#2E58F5"/>
              <path d="M7.205 4.2h2.604l-.434 6.398H7.639L7.205 4.2Zm1.302 9.912c-.383 0-.705-.121-.966-.364a1.24 1.24 0 0 1-.378-.91c0-.355.126-.649.378-.882.252-.243.574-.364.966-.364s.714.121.966.364c.252.233.378.527.378.882s-.131.658-.392.91c-.252.243-.57.364-.952.364Z" fill="#fff"/>
            </svg>
            <span class="profile__status-text" >{{ statusText }}</span>
          </div>
        </div>
        <div v-if="error" class="profile__status profile__status--mobile">
          <div class="profile__status-wr" v-for="(error_message, index) in error_messages" :key="index">
            <svg class="profile__status-icon" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8.5" cy="8.5" r="8.5" fill="#2E58F5"/>
              <path d="M7.205 4.2h2.604l-.434 6.398H7.639L7.205 4.2Zm1.302 9.912c-.383 0-.705-.121-.966-.364a1.24 1.24 0 0 1-.378-.91c0-.355.126-.649.378-.882.252-.243.574-.364.966-.364s.714.121.966.364c.252.233.378.527.378.882s-.131.658-.392.91c-.252.243-.57.364-.952.364Z" fill="#fff"/>
            </svg>
            <span class="profile__status-text" >{{ error_message }}</span>

          </div>
        </div>
        <div class="profile__controls" v-if="width <= 1024">
          <div class="profile__button profile__button--2">
            <button class="button button--2" type="submit" :disabled="loading || progress < 5">Отправить</button>
          </div>
        </div>
      </div>
      <div v-if="loading" class="profile__status profile__status--desktop">
        <div>
          <svg class="profile__status-icon" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8.5" cy="8.5" r="8.5" fill="#2E58F5"/>
            <path d="M7.205 4.2h2.604l-.434 6.398H7.639L7.205 4.2Zm1.302 9.912c-.383 0-.705-.121-.966-.364a1.24 1.24 0 0 1-.378-.91c0-.355.126-.649.378-.882.252-.243.574-.364.966-.364s.714.121.966.364c.252.233.378.527.378.882s-.131.658-.392.91c-.252.243-.57.364-.952.364Z" fill="#fff"/>
          </svg>
          <span class="profile__status-text" >{{ statusText }}</span>
        </div>
      </div>
      <div v-if="error" class="profile__status profile__status--desktop">
        <div class="profile__status-wr" v-for="(error_message, index) in error_messages" :key="index">
          <svg class="profile__status-icon" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8.5" cy="8.5" r="8.5" fill="#2E58F5"/>
            <path d="M7.205 4.2h2.604l-.434 6.398H7.639L7.205 4.2Zm1.302 9.912c-.383 0-.705-.121-.966-.364a1.24 1.24 0 0 1-.378-.91c0-.355.126-.649.378-.882.252-.243.574-.364.966-.364s.714.121.966.364c.252.233.378.527.378.882s-.131.658-.392.91c-.252.243-.57.364-.952.364Z" fill="#fff"/>
          </svg>
          <span class="profile__status-text" >{{ error_message }}</span>
        </div>
      </div>
      <div class="profile__controls" v-if="width > 1024">
        <div class="profile__button profile__button--2">
          <button class="button button--2" type="submit" :disabled="loading || progress < 5">Отправить</button>
        </div>
      </div>
    </form>
  </div>
  <Loader :show="docsLoading" />
</template>

<script>
import ProgressCircle from '@/components/ProgressCircle';
import UploadImage from '@/components/UploadImage';
import * as links from '../../store/links';
import Loader from '@/components/Loader.vue';

export default {
  name: 'ScoringDocuments',
  components: {
    Loader,
    UploadImage,
    ProgressCircle
  },
  data() {
    return {
      complete: false,
      loading: false,
      docsLoading: false,
      statusText: 'Проверка документов занимает до 20 минут. Вернитесь позже.',
      error_messages: [],
      width: 0,
      status: {
        show: false,
        text: 'Проверка документов занимает до 20 минут. Вернитесь позже.',
        final: 'Для дальнейшей проверки документов с Вами свяжется наш менеджер!',
        error: 'Пожалуйста, повторно загрузите документы.'

      },
      documents: {
        passport: {
          front: {
            error: false,
            valid: false,
            error_message: ''
          },
          back: {
            error: false,
            valid: false,
            error_message: ''
          }
        },
        driver_license: {
          front: {
            error: false,
            valid: false,
            error_message: ''
          },
          back: {
            error: false,
            valid: false,
            error_message: ''
          }
        },
        selfie: {
          front: {
            error: false,
            valid: false,
            error_message: ''
          }
        }
      }
    };
  },
  computed: {
    progress() {
      return this.documents.passport.front.valid + this.documents.passport.back.valid + this.documents.driver_license.front.valid + this.documents.driver_license.back.valid + this.documents.selfie.front.valid;
    },
    error() {
      return this.documents.passport.front.error && this.documents.passport.back.error && this.documents.driver_license.front.error && this.documents.driver_license.back.error && this.documents.selfie.front.error;
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    completed: {},
    loadingProcess: {},
    finalStatus: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    completed(val) {
      if (val) {
        this.complete = val;
      }
    },
    active: {
      handler(val) {
        if (val && this.loadingProcess) {
          this.status.show = true;
          if (!this.finalStatus) {
            this.statusText = this.status.final;
          } else {
            this.statusText = this.status.text;
          }
          this.loading = true;
          this.countDownTimer();
        }
      },
      deep: true
    },
    finalStatus: {
      handler(val) {
        if (val) {
          this.statusText = this.status.final;
        }
      },
    }
  },
  methods: {
    handleImages(data) {
      this.documents[data.type][data.side].valid = true;
      this.documents[data.type][data.side].error = false;
      this.documents[data.type][data.side].file = data.file;
    },
    checkDocuments() {
      if (this.$metrika) {
        this.$metrika.reachGoal('lkscoring_click_2step_confirm');

      }
      this.startDocumentsRequest();
    },
    startDocumentsRequest() {
      const formData = new FormData();
      formData.append('passport', this.documents.passport.front.file);
      formData.append('passportReg', this.documents.passport.back.file);
      formData.append('passportSelfie', this.documents.selfie.front.file);
      formData.append('driverLicenseFront', this.documents.driver_license.front.file);
      formData.append('driverLicenseBack', this.documents.driver_license.back.file);

      this.loading = true;
      this.docsLoading = true;

      this.axios.post(
        `${links.API}/send-docs`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          loading: {message: 'Trying to login...'},
        }
      )
      .then(async() => {
        this.statusText = this.status.text;
        this.$emit('saveStep');
        this.countDownTimer();
      })
      .catch((error) => {
        console.error(error);
        this.statusText = error.data.error_message;
        this.setError();
        this.$store.dispatch('auth/checkAuth', error);
      }).finally(() => {
        this.loading = false;
        this.docsLoading = false;
      });
    },
    countDownTimer() {
      const checkStatus = () => {
        if (this.loading) {
          this.checkRequest().then((status) => {
            if (status === 'FILE_UPLOADING' || status === 'STARTED') {
              setTimeout(checkStatus, 10000);
            }
          }).catch((error) => {
            console.error(error);
            this.loading = false;
            this.$store.dispatch('auth/checkAuth', error);
          });
        }
      };
      checkStatus();
    },
    async checkRequest() {
      return this.axios.get(
        `${links.API}/status`,
        {
          loading: {message: 'Trying to login...'},
        }
      )
      .then(async (response) => {
        const status = response.data.result_code;
        switch (status) {
          case 'FILE_UPLOADING':
          case 'STARTED':
            this.statusText = this.status.text;
            break;
          case 'FILE_REQUIRED':
            this.statusText = this.status.error;
            this.error_messages = [this.status.error];
            this.loading = false;
            this.setError();
            break;
          case 'FAILED':
            this.statusText = this.status.final;
            this.loading = false;
            this.setError();
            break;
          case 'SUCCESS':
            this.nextStep();
            this.$emit('userAdvancedToNextStep');
            break;
        }
        return status;
      });
    },
    nextStep() {
      this.status.show = false;
      this.loading = false;
      this.complete = true;
      this.$emit('readyStep');
    },
    setDefaultStatus(data) {
      this.documents[data.type][data.side].error = false;
      this.documents[data.type][data.side].valid = false;
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
    setError() {
      this.documents.passport.front.valid = false;
      this.documents.passport.back.valid = false;
      this.documents.driver_license.front.valid = false;
      this.documents.driver_license.back.valid = false;
      this.documents.selfie.front.valid = false;

      this.documents.passport.front.error = true;
      this.documents.passport.back.error = true;
      this.documents.driver_license.front.error = true;
      this.documents.driver_license.back.error = true;
      this.documents.selfie.front.error = true;

      this.documents.passport.front.error_message = '';
      this.documents.passport.back.error_message = '';
      this.documents.driver_license.front.error_message = '';
      this.documents.driver_license.back.error_message = '';
      this.documents.selfie.front.error_message = '';
    }
  },
  created() {
    this.width = window.innerWidth;
    window.addEventListener('resize', this.updateWidth);
  },
};
</script>